/* global
L
*/

var institute_marker = null;
var map = null;

function leaflet_screenshot (map, options) {
  map.zoomControl.remove();
  const map_container = $("#id_map_set_loaction");
  var layerGroupData = L.featureGroup().addTo(map);
  const geojson_institute = map_container.data("institute");
  let marker = null;
  L.Proj.geoJson(geojson_institute, {
    pointToLayer: function (feature, latlng) {
      marker = L.marker(latlng, { draggable: "true" }).addTo(layerGroupData);
    },
  });
  if (marker) {
    map.fitBounds(layerGroupData.getBounds());
    map.invalidateSize();
  }
}

function leaflet_set_location (map, options) {
  const map_container = $("#id_map_set_loaction");
  const institute_url = map_container.data("location-url");
  const institute_id = map_container.data("institute");
  var layerGroupData = L.featureGroup().addTo(map);
  const geocoder = L.Control.Geocoder.nominatim();
  institute_marker = null;

  function init () {
    initMapEvents();
    get_institute_location();
  }
  function initMapEvents () {
    $(window)
      .bind("invalidate_size", () => map.invalidateSize())
      .bind("get_institute_location", (e) => get_institute_location())
      .bind("save_institute_location", (e) => save_institute_location());
  }
  function save_institute_location () {
    const lat_lng = institute_marker.getLatLng();
    $.post(institute_url, { id: institute_id, lat: lat_lng.lat, lng: lat_lng.lng });
  }
  function get_institute_location () {
    $.get(`${institute_url}?id=${institute_id}`, function (data) {
      if ("address" in data) {
        set_marker_by_address(data.address);
      } else if ("point" in data) {
        set_marker_by_point(JSON.parse(data.point));
      }
    });
  }
  function set_marker_by_address (data) {
    geocoder.geocode(data, results => {
      const r = results[0];
      if (r) {
        layerGroupData.clearLayers();
        institute_marker = L.marker(r.center, { draggable: "true" }).addTo(layerGroupData);
        map.panTo(institute_marker.getLatLng());
        map.fitBounds(layerGroupData.getBounds());
        map.invalidateSize();
        set_lat_lng_values();
        init_marker_event();
      }
    });
  }
  function set_marker_by_point (data) {
    layerGroupData.clearLayers();
    L.Proj.geoJson(data, {
      pointToLayer: function (feature, latlng) {
        institute_marker = L.marker(latlng, { draggable: "true" });
        return institute_marker;
      },
    }).addTo(layerGroupData);
    map.panTo(institute_marker.getLatLng());
    map.fitBounds(layerGroupData.getBounds());
    map.invalidateSize();
    set_lat_lng_values();
    init_marker_event();
  }
  function set_lat_lng_values () {
    const lat_lng = institute_marker.getLatLng();
    $("#id_institutelocationform-longitude").val(lat_lng.lng);
    $("#id_institutelocationform-latitude").val(lat_lng.lat);
  }
  function init_marker_event () {
    institute_marker.on("dragend", () => set_lat_lng_values());
  }
  init();
}

function init_leaflet_map (map, options) {
  const map_container = $("#id_map_container");
  const GeoJsonUrl = map_container.data("url");
  var markers = L.markerClusterGroup({
    spiderfyOnMaxZoom: true,
    showCoverageOnHover: false,
    // zoomToBoundsOnClick: false,
    // disableClusteringAtZoom: 12,
    iconCreateFunction: function (cluster) {
      return L.divIcon({
        className: "marker-cluster",
        html: `<div style="background: linear-gradient(140.03deg, #AE619D 38.28%, rgba(79, 176, 191, 0.73) 96.04%);"><span>${cluster.getChildCount()}</span></div>`,
      });
    },
  });

  function init () {
    initMapEvents();
    // fetch GeoData
    $.when(ajaxCall(GeoJsonUrl, {})).done((data) => {
      if (data.features.length) {
        addMarkers(data);
      }
    });
  }
  function initMapEvents () {
    $(window)
      .bind("leaflet_map_data_changed", (e, data) => {
        $.when(addMarkers(data)).then((d) => $(window).trigger("invalidate_size"));
      })
      .bind("invalidate_size", () => map.invalidateSize());
  }
  function addMarkers (data) {
    markers.clearLayers();
    const layer = L.Proj.geoJson(data, {
      pointToLayer: function (feature, latlng) {
        return L.circleMarker(latlng, {
          radius: 7,
          fillColor: (feature.properties.institution_type === 2) ? ("#4FB0BF") : ("#AF619D"),
          color: (feature.properties.institution_type === 2) ? ("#4FB0BF") : ("#AF619D"),
          weight: 1,
          opacity: 1,
          fillOpacity: 0.8,
        }).bindPopup(
          `<a href="/asm/institute/${feature.id}/" data-modal-link="True" class="map-link"><p>${feature.properties.name}</p></a>`
        );
      },
    });
    markers.addLayers([layer]);
    map.addLayer(markers);
    if (markers.getBounds().isValid()) {
      map.fitBounds(markers.getBounds(), { padding: [50, 50] });
    } else {
      map.setView([0, 0], 1);
    }
    return markers;
  }
  init();
}

/********************************/
/* map on main */
function init_main_leaflet_map (map, options) {
  map.zoomControl = false;
  map.dragging.disable();
  map.touchZoom.disable();
  map.doubleClickZoom.disable();
  map.scrollWheelZoom.disable();
  map.boxZoom.disable();
  map.keyboard.disable();
  $(".leaflet-control-zoom").css("visibility", "hidden");

  const map_container = $("#id_map_container");
  const GeoJsonUrl = map_container.data("url");
  var markers = L.markerClusterGroup({
    spiderfyOnMaxZoom: false,
    showCoverageOnHover: false,
    zoomToBoundsOnClick: false,
    // disableClusteringAtZoom: 12,
    iconCreateFunction: function (cluster) {
      return L.divIcon({
        className: "marker-cluster",
        html: `<div style="background: linear-gradient(140.03deg, #AE619D 38.28%, rgba(79, 176, 191, 0.73) 96.04%); cursor: default;"><span>${cluster.getChildCount()}</span></div>`,
      });
    },
  });

  function init () {
    initMapEvents();
    // fetch GeoData
    $.when(ajaxCall(GeoJsonUrl, {})).done((data) => {
      if (data.features.length) {
        addMarkers(data);
      }
    });
  }
  function initMapEvents () {
    $(window)
      .bind("leaflet_map_data_changed", (e, data) => {
        $.when(addMarkers(data)).then((d) => $(window).trigger("invalidate_size"));
      })
      .bind("invalidate_size", () => map.invalidateSize());
    const text = $("#map-text");
    const target = $(".leaflet-bottom.leaflet-left");
    target.prepend(text);
    document.getElementById("map-text").style.display = "flex";
    $(".leaflet-control-scale.leaflet-control").hide();
  }
  function addMarkers (data) {
    markers.clearLayers();
    const layer = L.Proj.geoJson(data, {
      pointToLayer: function (feature, latlng) {
        return L.circleMarker(latlng, {
          radius: 7,
          fillColor: (feature.properties.institution_type === 2) ? ("#4FB0BF") : ("#AF619D"),
          color: (feature.properties.institution_type === 2) ? ("#4FB0BF") : ("#AF619D"),
          weight: 1,
          opacity: 1,
          fillOpacity: 0.8,
        });
      },
    });
    markers.addLayers([layer]);
    map.addLayer(markers);
    // if (markers.getBounds().isValid()) {
    //   map.fitBounds(markers.getBounds(), { padding: [250, 250] });
    // } else {
    //   map.setView([0, 0], 1);
    // }
    map.setView([50, -8], 1);
    return markers;
  }
  init();
}
/********************************/

function ajaxCall (url, data, method) {
  data = typeof data !== "undefined" ? data : {};
  method = typeof method !== "undefined" ? method : "GET";
  return $.ajax({
    method: method,
    url: url,
    data: data,
    dataType: "json",
  });
}
